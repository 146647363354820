import { isOfflineApi } from '@/utils/api';
import {
  captureException,
  init,
  wrapCreateBrowserRouterV6,
  type BrowserOptions,
} from '@sentry/react';
import { createBrowserRouter } from 'react-router-dom';

const options: BrowserOptions = {
  dsn: 'https://4e41daa0beec410e99fc0c4fd830bd24@o372675.ingest.sentry.io/5896641',
};

export const SentryService = {
  init: (config: BrowserOptions = {}) => {
    const isProd = process.env.NODE_ENV === 'production';
    const environment = window.location.href.includes('stage') ? 'stage' : 'production';
    const release = process.env.RELEASE_NAME;

    if (isProd && release && !isOfflineApi()) {
      init({
        ...options,
        ...config,
        environment,
        release,
      });
    }
  },
  trackError: captureException,
  sentryCreateBrowserRouter: wrapCreateBrowserRouterV6(createBrowserRouter),
};
