import { Router } from '@/router';
import { MuiTheme } from '@/theme';
import { MuiThemeProvider } from '@material-ui/core';
import type { FC } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App: FC = () => {
  return (
    <MuiThemeProvider theme={MuiTheme}>
      <Router />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
    </MuiThemeProvider>
  );
};

export default App;
